import axios from "axios";
import React, { Dispatch, useState } from "react";
import { BASE_URL } from "../../../api/constants";
import shareB from "../../../assets/Svgs/shareB.svg";
import shareY from "../../../assets/Svgs/shareY.svg";
import { ReactComponent as CloseIcon } from "../../../assets/Svgs/icon-close.svg";
import { ReactComponent as CopyIcon } from "../../../assets/Svgs/icon-copy.svg";
import {
  ModalOverlay,
  ModalContent,
  ModalTitle,
  ModalInputWrapper,
  UrlInput,
  CancelButton,
  CopyButton,
} from "./share.style"

interface IProps {
  setText: Dispatch<React.SetStateAction<string>>;
  filename: string;
  email: string;
  shortName: string;
}

function encodeFileName(filename: string): string {
  const parts = filename.split("/");
  const encodedParts = parts.map((part) => {
    if (/[^\x00-\x7F]/.test(part)) {
      return encodeURIComponent(part);
    }
    return part;
  });
  return encodedParts.join("/");
}

async function copyToClipboard(text: string): Promise<boolean> {
  if (navigator.clipboard && window.isSecureContext) {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (err) {
      console.error("Failed to copy with Clipboard API:", err);
    }
  }

  try {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    document.body.appendChild(textarea);
    textarea.select();
    const success = document.execCommand("copy");
    document.body.removeChild(textarea);
    return success;
  } catch (err) {
    console.error("Failed to copy with execCommand:", err);
    return false;
  }
}

const fileShare = async (email: string, filename: string) => {
  const encodedFilename = encodeFileName(filename);

  try {
    const res = await axios.get(
      `${BASE_URL}/tree/downloadFile/${encodedFilename}`,
      {
        withCredentials: true,
      }
    );

    await axios.post(
      `${BASE_URL}/fileLogs/sendFileLog`,
      { email, action: "Share", fileName: filename },
      { withCredentials: true }
    );

    let processedUrl = res.data;

    if (processedUrl.includes(" ")) {
      processedUrl = processedUrl.replace(/\s+/g, "%20");
    }

    try {
      const url = new URL(processedUrl);
      const pathParts = url.pathname.split("/");
      const encodedParts = pathParts.map((part) => {
        if (/[^\x00-\x7F]/.test(part)) {
          return encodeURIComponent(decodeURIComponent(part));
        }
        return part;
      });
      url.pathname = encodedParts.join("/");
      processedUrl = url.toString();
    } catch (e) {
      console.error("Error processing URL:", e);
    }

    return processedUrl;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const FileShare = ({ setText, shortName, email, filename }: IProps) => {
  const [shareLogo, setShareLogo] = useState(shareB);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState<string | null>(null);

  const setTheName = () => {
    setText(shortName);
  };

  const handleShare = async () => {
    setText("טוען...");
    const link = await fileShare(email, filename);

    if (!link) {
      setText("שגיאה בהעתקת הקישור");
      setTimeout(setTheName, 3000);
      return;
    }

    setShareUrl(link);

    if (navigator.share) {
      try {
        await navigator.share({
          title: filename,
          text: "שיתוף קובץ",
          url: link,
        });
        setText("הקובץ שותף בהצלחה");
      } catch (err) {
        setShowShareModal(true);
      }
    } else {
      setShowShareModal(true);
    }
  };

  const handleCopy = async () => {
    if (!shareUrl) return;

    const success = await copyToClipboard(shareUrl);
    if (success) {
      setText("קישור לקובץ הועתק בהצלחה.");
      setShowShareModal(false);
    } else {
      setText("שגיאה בהעתקת הקישור");
    }
    setTimeout(setTheName, 3000);
  };

  return (
    <>
      <button
        onTouchStart={() => setShareLogo(shareY)}
        onMouseDown={() => setShareLogo(shareY)}
        onMouseUp={() => setShareLogo(shareB)}
        onTouchEnd={() => setShareLogo(shareB)}
        onClick={handleShare}
      >
        <img className="share" src={shareLogo} alt="שיתוף" />
      </button>

      {showShareModal && shareUrl && (
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>שיתוף קובץ</ModalTitle>
            <ModalInputWrapper>
              <UrlInput type="text" readOnly value={shareUrl} />
              <CopyButton onClick={handleCopy}>
                <CopyIcon />
              </CopyButton>
            </ModalInputWrapper>
            <CancelButton onClick={() => setShowShareModal(false)}>
              <CloseIcon />
            </CancelButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};