import styled from "styled-components";

export const LogOutWrapper = styled.div`
  width: 75vw;
  height: 24.5vh;
  display: flex;
  flex-direction: column;
  align-items: bottom;
  justify-content: bottom;

  text-align: bottom;
`;
export const BottomWrapper = styled.div`
  width: 70vw;
  height: 7vh;
  display: flex;
  flex-direction: column;
  align-items: bottom;
  justify-content: bottom;

  text-align: bottom;
`;
export const PersonalArea = styled.a`
  display: flex;
  align-items: center;
  justify-content: bottom;
  text-align: bottom;

  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  height: 30vh;
  margin-right: 8vw;
  margin-top: 12vh;
  text-decoration: none;
`;

export const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: right; */
  color: #ffffff;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  width: 70vw;
  height: 10vh;
  margin-right: 2vw;
  text-decoration: none;
`;
export const Img = styled.img`
  width: 2rem;
  height: 2rem;
  margin-left: 3vw;
  border: none;
`;
export const MadorLogo = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 2vw;
  border: none;
`;
