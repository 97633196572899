import styled from "styled-components";

export const ModalOverlay = styled.div`
position: fixed;
inset: 0;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
padding: 1rem;
`;

export const ModalContent = styled.div`
background-color: white;
border-radius: 0.5rem;
padding: 1.5rem;
max-width: 24rem;
width: 100%;
position: relative;
`;

export const ModalTitle = styled.h3`
font-size: 1.125rem;
font-weight: 500;
margin-bottom: 1rem;
margin-top: 0;
padding-left: 30px;
`;

export const ModalInputWrapper = styled.div`
display: flex;
align-items: center;
background: #f5f5f5;
border: 1px solid #e2e8f0;
border-radius: 0.5rem;
overflow: hidden;
width: 100%;
`;

export const UrlInput = styled.input`
flex-grow: 1;
border: none;
background: transparent;
outline: none;
padding: 0.5rem;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
`;

export const CancelButton = styled.button`
position: absolute;
left: 0rem;
top: 40%;
transform: translateY(-140%);
background: transparent;
border: none;
cursor: pointer;
padding: 0;

svg {
  width: 50%;
  height: 50%;
}
`;

export const CopyButton = styled.button`
padding: 0.5rem 1rem;
border: none;
border-left: 1px solid #e2e8f0;
cursor: pointer;

&:hover {
  background-color: #2c5282;
}

svg {
  width: 20px;
  height: 20px;
}
`;
