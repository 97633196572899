import {
  BottomWrapper,
  PersonalArea,
  LogOutWrapper,
  SectionWrapper,
  Img,
  MadorLogo,
} from "./styles";
import logOut from "../../../assets/Svgs/Group 115.svg";
import madorLogo from "../../../assets/madorLogo.png";
import { BASE_URL } from "../../../api/constants";

export const Bottom = () => {
  return (
    <>
      <LogOutWrapper>
        <PersonalArea href={`${BASE_URL}/auth/logout`}>
          <Img src={logOut} alt=""></Img>
          <p>התנתקות</p>
        </PersonalArea>
      </LogOutWrapper>
      <BottomWrapper>
        <SectionWrapper>
          <MadorLogo src={madorLogo} alt=""></MadorLogo>
          <p>פותח ע”י מחלקת דיגיטל ז"י</p>
        </SectionWrapper>
      </BottomWrapper>
    </>
  );
};
